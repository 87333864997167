export default function BitmapImporter(lib, cjs, ss, p) {
  (lib.sence_1_text_bitmap_1 = function () {
    this.spriteSheet = ss["sence1_text_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_1_text_bitmap_2 = function () {
    this.spriteSheet = ss["sence1_text_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_1_text_bitmap_3 = function () {
    this.spriteSheet = ss["sence1_text_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_2_text_bitmap_1 = function () {
    this.spriteSheet = ss["sence2_text_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_2_text_bitmap_2 = function () {
    this.spriteSheet = ss["sence2_text_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_2_text_bitmap_3 = function () {
    this.spriteSheet = ss["sence2_text_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_3_text_bitmap_1 = function () {
    this.spriteSheet = ss["sence3_text_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_4_text_bitmap_1 = function () {
    this.spriteSheet = ss["sence4_text_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_5_text_bitmap_1 = function () {
    this.spriteSheet = ss["sence5_text_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_5_text_bitmap_2 = function () {
    this.spriteSheet = ss["sence5_text_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_5_text_bitmap_3 = function () {
    this.spriteSheet = ss["sence5_text_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_6_text_bitmap_1 = function () {
    this.spriteSheet = ss["sence6_text_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_6_text_bitmap_2 = function () {
    this.spriteSheet = ss["sence6_text_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_6_text_bitmap_3 = function () {
    this.spriteSheet = ss["sence6_text_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_4_text_bitmap_2 = function () {
    this.spriteSheet = ss["sence4_text_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_4_text_bitmap_3 = function () {
    this.spriteSheet = ss["sence4_text_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_3_text_bitmap_2 = function () {
    this.spriteSheet = ss["sence3_text_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.sence_3_text_bitmap_3 = function () {
    this.spriteSheet = ss["sence3_text_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.sence6_bitmap_2 = function () {
    this.spriteSheet = ss["sence6_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.sence6_bitmap_3 = function () {
    this.spriteSheet = ss["sence6_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.sence6_bitmap_4 = function () {
    this.spriteSheet = ss["sence6_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.sence6_bitmap_5 = function () {
    this.spriteSheet = ss["sence6_atlas_"];
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib.sence6_bitmap_6 = function () {
    this.spriteSheet = ss["sence6_atlas_"];
    this.gotoAndStop(4);
  }).prototype = p = new cjs.Sprite();

  (lib.sence6_bitmap_7 = function () {
    this.spriteSheet = ss["sence6_atlas_"];
    this.gotoAndStop(5);
  }).prototype = p = new cjs.Sprite();

  (lib.sence6_bitmap_8 = function () {
    this.spriteSheet = ss["sence6_atlas_"];
    this.gotoAndStop(6);
  }).prototype = p = new cjs.Sprite();

  (lib.sence6_bitmap_9 = function () {
    this.spriteSheet = ss["sence6_atlas_"];
    this.gotoAndStop(7);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_1 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_10 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_11 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_12 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_13 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(4);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_14 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(5);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_15 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(6);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_16 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(7);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_17 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(8);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_18 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(9);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_19 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(10);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_2 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(11);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_20 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(12);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_21 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(13);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_22 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(14);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_23 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(15);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_3 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(16);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_4 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(17);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_5 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(18);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_6 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(19);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_7 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(20);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_8 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(21);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence4_9 = function () {
    this.spriteSheet = ss["sence4_atlas_"];
    this.gotoAndStop(22);
  }).prototype = p = new cjs.Sprite();

  (lib.laptop1 = function () {
    this.spriteSheet = ss["sence3_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.laptop2 = function () {
    this.spriteSheet = ss["sence3_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.laptop3 = function () {
    this.spriteSheet = ss["sence3_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.laptop_original = function () {
    this.spriteSheet = ss["sence3_atlas_"];
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap1 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap10 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap11 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap12 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap13 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(4);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap14 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(5);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap15 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(6);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap16 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(7);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap17 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(8);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap18 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(9);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap19 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(10);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap2 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(11);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap20 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(12);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap3 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(13);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap4 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(14);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap5 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(15);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap6 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(16);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap7 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(17);
  }).prototype = p = new cjs.Sprite();

  (lib.sence1_bitmap9 = function () {
    this.spriteSheet = ss["sence1_atlas_"];
    this.gotoAndStop(18);
  }).prototype = p = new cjs.Sprite();

  (lib.Ice2 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap1 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap10 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap12 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap13 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(4);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap14 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(5);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap15 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(6);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap16 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(7);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap2 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(8);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap3 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(9);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap4 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(10);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap5 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(11);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap6 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(12);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap7 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(13);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap8 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(14);
  }).prototype = p = new cjs.Sprite();

  (lib.single_phone_bitmap9 = function () {
    this.spriteSheet = ss["sence2_atlas_"];
    this.gotoAndStop(15);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_1 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_10 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_11 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(2);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_12 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(3);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_13 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(4);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_14 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(5);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_15 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(6);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_16 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(7);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_17 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(8);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_18 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(9);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_19 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(10);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_2 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(11);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_20 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(12);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_21 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(13);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_22 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(14);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_23 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(15);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_24 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(16);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_25 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(17);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_26 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(18);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_27 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(19);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_28 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(20);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_29 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(21);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_3 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(22);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_30 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(23);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_31 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(24);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_32 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(25);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_33 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(26);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_34 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(27);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_35 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(28);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_36 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(29);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_37 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(30);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_38 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(31);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_39 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(32);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_4 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(33);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_40 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(34);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_41 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(35);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_42 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(36);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_43 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(37);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_44 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(38);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_45 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(39);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_46 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(40);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_47 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(41);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_48 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(42);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_49 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(43);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_5 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(44);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_50 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(45);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_51 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(46);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_52 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(47);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_6 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(48);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_7 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(49);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_8 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(50);
  }).prototype = p = new cjs.Sprite();

  (lib.bitmap_sence5_9 = function () {
    this.spriteSheet = ss["sence5_atlas_"];
    this.gotoAndStop(51);
  }).prototype = p = new cjs.Sprite();
}
