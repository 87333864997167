<template>
  <div>
  <animate-banner ></animate-banner>
  </div>
</template>

<script>
import AnimateBanner from './components/AnimateBannerComponent.vue'

export default {
  name: 'App',
  components: {
    AnimateBanner
  }
}
</script>

<style>
html{
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  overflow: hidden;
}
</style>
