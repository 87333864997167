import bitmapImporter from './bitmapImporter';
import symbolImporter from './symbolImporter';
import TTF from '@/TweenTransformer';

export default function libraryGenerator(cjs, an,isEN) {
var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"sence1_atlas_", frames: [[1792,0,239,691],[1181,1205,189,356],[966,1205,213,325],[0,907,221,372],[1189,831,221,372],[599,1242,189,163],[966,831,221,372],[528,825,287,415],[223,1219,189,325],[1727,1275,220,203],[223,907,229,310],[1792,693,239,580],[1537,0,253,732],[1041,0,494,829],[817,825,147,609],[528,0,511,823],[414,1242,183,314],[0,0,526,905],[1537,734,188,715]]},
		{name:"sence2_atlas_", frames: [[0,986,273,246],[832,356,178,167],[510,1170,84,91],[844,1124,86,112],[932,1124,85,112],[497,704,471,309],[0,0,495,984],[497,0,333,702],[420,1015,178,153],[832,525,178,162],[722,1015,120,189],[600,1015,120,194],[275,986,143,354],[832,0,156,354],[844,1015,99,107],[420,1170,88,100]]},
		{name:"sence3_atlas_", frames: [[0,407,367,247],[0,656,367,192],[0,850,367,152],[0,0,367,405]]},
		{name:"sence4_atlas_", frames: [[82,550,85,44],[0,376,80,374],[282,0,152,374],[436,0,48,374],[82,376,140,172],[363,807,98,50],[72,787,102,53],[259,774,102,53],[82,596,202,103],[0,752,70,103],[405,702,81,103],[444,527,41,44],[444,376,50,103],[176,787,55,28],[468,573,28,26],[169,550,27,28],[444,481,44,44],[82,701,175,84],[224,486,218,108],[286,596,180,104],[224,376,218,108],[259,702,144,70],[0,0,280,374]]},
		{name:"sence5_atlas_", frames: [[1520,0,426,739],[957,1288,103,61],[852,1288,103,85],[1928,1108,108,120],[532,1079,110,132],[1948,140,97,132],[1948,0,97,138],[1810,1108,116,145],[1338,578,128,149],[1599,1236,119,134],[532,1213,108,120],[1123,1340,44,47],[1928,1230,108,111],[1720,1255,107,105],[642,1288,107,105],[751,1288,99,105],[1948,274,94,105],[1948,381,89,105],[1948,488,71,95],[1948,585,65,89],[1829,1255,59,83],[1468,578,47,66],[1123,1288,44,50],[2015,585,31,35],[2021,488,21,23],[2021,513,10,12],[1069,1288,52,77],[532,1335,52,77],[0,505,647,572],[649,984,522,302],[586,1335,51,61],[1468,646,37,61],[1765,1362,41,32],[0,0,860,503],[2015,622,33,32],[791,505,69,51],[1943,1343,69,45],[1890,1255,36,31],[1720,1362,43,31],[649,578,687,404],[1948,676,84,48],[957,1351,110,48],[649,505,140,48],[1829,1343,112,48],[1173,1105,424,258],[1338,741,488,362],[0,1079,530,294],[862,0,656,576],[1599,1105,209,129],[1828,977,210,129],[1173,984,153,88],[1828,741,206,234]]},
		{name:"sence6_atlas_", frames: [[1319,1146,112,324],[1157,1146,160,324],[1157,820,272,324],[939,0,927,818],[0,0,937,941],[897,1615,440,390],[0,943,1155,670],[0,1615,895,450]]},
		{name:"sence1_text_atlas_", frames: [[0,0,1188,164],[0,199,321,25],[0,166,321,31]]},
		{name:"sence2_text_atlas_", frames: [[0,0,1024,192],[0,194,317,60],[319,194,280,50]]},
		{name:"sence3_text_atlas_", frames: [[0,0,712,192],[714,0,263,71],[714,73,185,44]]},
		{name:"sence4_text_atlas_", frames: [[0,0,712,188],[714,0,271,56],[714,58,271,53]]},
		{name:"sence5_text_atlas_", frames: [[0,0,1512,200],[1514,58,239,56],[1514,0,337,56]]},
		{name:"sence6_text_atlas_", frames: [[0,0,696,184],[698,0,292,77],[698,79,283,75]]},
];
// symbols:
bitmapImporter(lib,cjs,ss,p);
symbolImporter(lib,cjs,ss,p);

// stage content:
(lib.sence5 = function(mode,startPosition,loop=false) {
	this.initialize(mode,startPosition,loop,{});
	// 圖層 1
	this.instance = new lib.phone();
	this.instance.parent = this;
	this.instance.setTransform(767,313.5);

	this.instance_1 = new lib.rc_conbine_clip();
	this.instance_1.parent = this;
	this.instance_1.setTransform(960,552.5);

	this.instance_2 = new lib.circle2_clip();
	this.instance_2.parent = this;
	this.instance_2.setTransform(1039.2,362.8);

	this.instance_3 = new lib.triangle_clip();
	this.instance_3.parent = this;
	this.instance_3.setTransform(510.3,601.4,0.75,0.75,0,0,0,5.2,0.4);

	this.instance_4 = new lib.triangle_clip();
	this.instance_4.parent = this;
	this.instance_4.setTransform(908.2,559.4,0.33,0.33,-90,0,0,5,0.1);

	this.instance_5 = new lib.triangle_clip();
	this.instance_5.parent = this;
	this.instance_5.setTransform(985.6,371.4,1,1,150,0,0,4.5,-0.3);

	this.instance_6 = new lib.triangle_clip();
	this.instance_6.parent = this;
	this.instance_6.setTransform(916.8,374.2,0.33,0.33,0,0,0,4.9,0.1);

	this.instance_7 = new lib.triangle_clip();
	this.instance_7.parent = this;
	this.instance_7.setTransform(376.1,141.7,1,1,0,0,0,4.7,0);

	this.line_inst = new lib.line1();
	this.line_inst.name = "line_inst";
	this.line_inst.parent = this;
	this.line_inst.setTransform(483.1,100.1,1.2,1.2,0,0,0,0.1,3.1);

	this.line_inst_1 = new lib.line1();
	this.line_inst_1.name = "line_inst_1";
	this.line_inst_1.parent = this;
	this.line_inst_1.setTransform(1138.1,383.1,1.3,1.45,180,0,0,0.1,3.1);

	this.instance_8 = new lib.rect_clip();
	this.instance_8.parent = this;
	this.instance_8.setTransform(1110.5,280.5,1.5,1.5,90,0,0,46.4,40.4);

	this.instance_9 = new lib.rect_clip();
	this.instance_9.parent = this;
	this.instance_9.setTransform(519.8,142.7,1,1,0,0,0,17.4,23.6);

	this.line_inst_2 = new lib.line1();
	this.line_inst_2.name = "line_inst_2";
	this.line_inst_2.parent = this;
	this.line_inst_2.setTransform(948,601,0.9,1,-90,0,0,-1,3.7);

	this.instance_10 = new lib.dot1_clip();
	this.instance_10.parent = this;
	this.instance_10.setTransform(1006.4,180.4,0.76,0.76,-90,0,0,38,-3.3);

	this.instance_11 = new lib.dot1_clip();
	this.instance_11.parent = this;
	this.instance_11.setTransform(445.8,175.8,0.76,0.76,-90,0,0,38,-3.3);

	this.instance_12 = new lib.dot1_clip();
	this.instance_12.parent = this;
	this.instance_12.setTransform(420.6,366.7,1,1,0,0,0,37.9,-3.3);

	this.instance_13 = new lib.circle_clip();
	this.instance_13.parent = this;
	this.instance_13.setTransform(508.5,464.5,1,1,0,0,0,23.5,23.5);

	this.instance_14 = new lib.line1();
	this.instance_14.parent = this;
	this.instance_14.setTransform(402.3,428.6,1,1,0,0,0,0.1,3.1);

	this.instance_15 = new lib.circle_clip();
	this.instance_15.parent = this;
	this.instance_15.setTransform(431.5,314.3,1,1,90,0,0,23.6,23.5);

	this.instance_16 = new lib.rect_clip();
	this.instance_16.parent = this;
	this.instance_16.setTransform(1046.9,498.6,1,1,0,0,0,46.3,40.4);

	this.timeline.addTween(TTF(cjs.Tween.get({})).toE({state:[{t:this.instance_16},{t:this.instance_15},{t:this.instance_14},{t:this.instance_13},{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.line_inst_2},{t:this.instance_9},{t:this.instance_8},{t:this.line_inst_1},{t:this.line_inst},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence2 = function(mode,startPosition,loop=false) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層 1
	this.instance = new lib.drink();
	this.instance.parent = this;
	this.instance.setTransform(750.8,314.8);

	this.instance_1 = new lib.triangle2_clip();
	this.instance_1.parent = this;
	this.instance_1.setTransform(972.8,465.3);

	this.instance_2 = new lib.circle3_clip();
	this.instance_2.parent = this;
	this.instance_2.setTransform(1140.2,499.3,1,1,180,0,0,5.8,0);

	this.instance_3 = new lib.circle3_clip();
	this.instance_3.parent = this;
	this.instance_3.setTransform(981.9,580,1,1,0,0,0,5.8,0);

	this.instance_4 = new lib.rect2_clip();
	this.instance_4.parent = this;
	this.instance_4.setTransform(1051.2,160.2,1,1,0,0,0,0.1,0.1);

	this.instance_5 = new lib.rect2_clip();
	this.instance_5.parent = this;
	this.instance_5.setTransform(504,558.4,1,1,0,0,0,0.1,0.1);

	this.instance_6 = new lib.rect2_clip();
	this.instance_6.parent = this;
	this.instance_6.setTransform(431.2,247.9,1.7,1.7,90,0,0,0.1,0.1);

	this.instance_7 = new lib.line1();
	this.instance_7.parent = this;
	this.instance_7.setTransform(1055.8,458.6,1.08,1.2,0,0,0,0.1,-0.4);

	this.instance_8 = new lib.dot1_clip();
	this.instance_8.parent = this;
	this.instance_8.setTransform(1077.5,148.3,0.8,0.8,0,0,0,38,-3.3);

	this.instance_9 = new lib.dot1_clip();
	this.instance_9.parent = this;
	this.instance_9.setTransform(1169.6,524.5,1,1,0,0,0,38,-3.3);

	this.instance_10 = new lib.line1();
	this.instance_10.parent = this;
	this.instance_10.setTransform(865,604.8,0.9,1,-90,0,0,0.2,-0.4);

	this.instance_11 = new lib.line1();
	this.instance_11.parent = this;
	this.instance_11.setTransform(1116.2,276.5,1.26,1.4,0,180,0,0.2,-0.5);

	this.instance_12 = new lib.triangle_clip();
	this.instance_12.parent = this;
	this.instance_12.setTransform(1171.5,136.5,1.1,1.1,180,0,0,4.5,-0.1);

	this.instance_13 = new lib.line1();
	this.instance_13.parent = this;
	this.instance_13.setTransform(413.1,548.2,0.9,1,0,-90,90,0.2,-0.4);

	this.instance_14 = new lib.line1();
	this.instance_14.parent = this;
	this.instance_14.setTransform(355,381.4,1.35,1.5,0,180,0,0.1,-0.4);

	this.instance_15 = new lib.dot1_clip();
	this.instance_15.parent = this;
	this.instance_15.setTransform(357.1,175.5,0.75,0.75,-90,0,0,37.9,-3.2);

	this.instance_16 = new lib.dot1_clip();
	this.instance_16.parent = this;
	this.instance_16.setTransform(472.2,426.5,1,1,90,0,0,38,-3.3);

	this.instance_17 = new lib.triangle_clip();
	this.instance_17.parent = this;
	this.instance_17.setTransform(350,374.9,1,1,-90,0,0,4.6,0.1);

	this.timeline.addTween(TTF(cjs.Tween.get({})).toE({state:[{t:this.instance_17},{t:this.instance_16},{t:this.instance_15},{t:this.instance_14},{t:this.instance_13},{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence3 = function(mode,startPosition,loop=false) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層 1
	this.instance = new lib.laptop_clip();
	this.instance.parent = this;
	this.instance.setTransform(696.7,366.5);

	this.instance_1 = new lib.line1();
	this.instance_1.parent = this;
	this.instance_1.setTransform(415,105.3,0.85,0.9,0,0,0,0.4,0.2);

	this.instance_2 = new lib.line1();
	this.instance_2.parent = this;
	this.instance_2.setTransform(1061.9,219.1,0.944,1,0,0,0,0.4,0.3);

	this.instance_3 = new lib.circle2_clip();
	this.instance_3.parent = this;
	this.instance_3.setTransform(1066.3,492.6);

	this.instance_4 = new lib.triangle_blue_clip();
	this.instance_4.parent = this;
	this.instance_4.setTransform(988.8,484.7,0.476,0.476,180);

	this.instance_5 = new lib.circle2_clip();
	this.instance_5.parent = this;
	this.instance_5.setTransform(989.3,243.3,0.87,0.87,0,0,0,0.5,0.5);

	this.instance_6 = new lib.triangle_blue_clip();
	this.instance_6.parent = this;
	this.instance_6.setTransform(1004.5,281.4,0.9,0.9,134,0,0,0.1,-0.3);

	this.instance_7 = new lib.triangle_blue_clip();
	this.instance_7.parent = this;
	this.instance_7.setTransform(1124.4,191.2,0.6,0.6,-45,0,0,0,0.1);

	this.instance_8 = new lib.triangle_blue_clip();
	this.instance_8.parent = this;
	this.instance_8.setTransform(966.8,121.4,1,1,180);

	this.instance_9 = new lib.triangle_blue_clip();
	this.instance_9.parent = this;
	this.instance_9.setTransform(451.6,495.6);

	this.instance_10 = new lib.rect_blue_clip();
	this.instance_10.parent = this;
	this.instance_10.setTransform(1117.6,252,0.57,0.57,0,0,0,0.1,0.2);

	this.instance_11 = new lib.rect_blue_clip();
	this.instance_11.parent = this;
	this.instance_11.setTransform(1113.6,503.5);

	this.instance_12 = new lib.rect_blue_clip();
	this.instance_12.parent = this;
	this.instance_12.setTransform(500.8,221.7);

	this.instance_13 = new lib.triangle_clip();
	this.instance_13.parent = this;
	this.instance_13.setTransform(1172.3,470.7,0.33,0.33,0,0,180,4.4,-0.1);

	this.instance_14 = new lib.rc_conbine_clip();
	this.instance_14.parent = this;
	this.instance_14.setTransform(1164,525.4);

	this.instance_15 = new lib.dot1_clip();
	this.instance_15.parent = this;
	this.instance_15.setTransform(1043.4,134.5,0.7,0.7,90,0,0,38.1,-3.3);

	this.instance_16 = new lib.dot1_clip();
	this.instance_16.parent = this;
	this.instance_16.setTransform(524.9,171.2,0.9,0.9,0,0,0,38.1,-3.2);

	this.instance_17 = new lib.line1();
	this.instance_17.parent = this;
	this.instance_17.setTransform(339.2,575.8,1.2,1.4,0,180,0,0.1,0);

	this.instance_18 = new lib.dot1_clip();
	this.instance_18.parent = this;
	this.instance_18.setTransform(386.9,301.9,0.9,0.9,90,0,0,38.1,-3.3);

	this.instance_19 = new lib.dot1_clip();
	this.instance_19.parent = this;
	this.instance_19.setTransform(494.6,422.4,0.9,0.9,0,0,0,38.1,-3.2);

	this.instance_20 = new lib.rect3_clip();
	this.instance_20.parent = this;
	this.instance_20.setTransform(415.1,306,1,1,0,0,0,0,8.9);

	this.instance_21 = new lib.triangle_blue_clip();
	this.instance_21.parent = this;
	this.instance_21.setTransform(619.7,150.5,1,1,30);

	this.timeline.addTween(TTF(cjs.Tween.get({})).toE({state:[{t:this.instance_21},{t:this.instance_20},{t:this.instance_19},{t:this.instance_18},{t:this.instance_17},{t:this.instance_16},{t:this.instance_15},{t:this.instance_14},{t:this.instance_13},{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence4 = function(mode,startPosition,loop=false) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層 1
	this.instance = new lib.kit_set();
	this.instance.parent = this;
	this.instance.setTransform(707.5,361.9);

	this.instance_1 = new lib.triangle_red_clip();
	this.instance_1.parent = this;
	this.instance_1.setTransform(456.3,265.6,1,1,180,0,0,0.1,0);

	this.instance_2 = new lib.line1();
	this.instance_2.parent = this;
	this.instance_2.setTransform(1128.1,266.9,1.108,1.15,0,180,0,0.2,-0.1);

	this.instance_3 = new lib.dot1_clip();
	this.instance_3.parent = this;
	this.instance_3.setTransform(1061.8,146.1,0.85,0.85,90,0,0,37.9,-3.3);

	this.instance_4 = new lib.circle2_clip();
	this.instance_4.parent = this;
	this.instance_4.setTransform(1045.5,561.8,1,1,90,0,0,0.3,-0.1);

	this.instance_5 = new lib.circle_red_clip();
	this.instance_5.parent = this;
	this.instance_5.setTransform(451.2,207,0.7,0.7);

	this.instance_6 = new lib.circle_red_clip();
	this.instance_6.parent = this;
	this.instance_6.setTransform(1041.5,211.4);

	this.instance_7 = new lib.circle_red_clip();
	this.instance_7.parent = this;
	this.instance_7.setTransform(1048.8,507.4);

	this.instance_8 = new lib.triangle_red_clip();
	this.instance_8.parent = this;
	this.instance_8.setTransform(1154.3,151.8,1.6,1.6,15,0,0,0.2,0.1);

	this.instance_9 = new lib.triangle_red_clip();
	this.instance_9.parent = this;
	this.instance_9.setTransform(1027.8,631.6,1,1,0,0,0,0.1,0);

	this.instance_10 = new lib.triangle_red_clip();
	this.instance_10.parent = this;
	this.instance_10.setTransform(299.4,338.5,1.5,1.5,-90,0,0,0.1,-0.1);

	this.instance_11 = new lib.triangle_clip();
	this.instance_11.parent = this;
	this.instance_11.setTransform(1016.2,447.9,0.35,0.35,90,0,0,4.7,-0.3);

	this.instance_12 = new lib.rc_conbine_clip();
	this.instance_12.parent = this;
	this.instance_12.setTransform(1067.8,458.7);

	this.instance_13 = new lib.circle2_clip();
	this.instance_13.parent = this;
	this.instance_13.setTransform(321.4,217.5,0.84,0.84,90,0,0,0.3,-0.1);

	this.instance_14 = new lib.line1();
	this.instance_14.parent = this;
	this.instance_14.setTransform(394.3,240.8,1.2,1.3,0,180,0,0.1,0.1);

	this.instance_15 = new lib.dot1_clip();
	this.instance_15.parent = this;
	this.instance_15.setTransform(1220.8,455,0.85,0.85,-90,0,0,37.9,-3.3);

	this.instance_16 = new lib.dot1_clip();
	this.instance_16.parent = this;
	this.instance_16.setTransform(355.8,304.7,0.7,0.7,0,0,0,38.1,-3.1);

	this.instance_17 = new lib.dot1_clip();
	this.instance_17.parent = this;
	this.instance_17.setTransform(533.2,546.5,0.8,0.8,90,0,0,37.9,-3.2);

	this.instance_18 = new lib.line1();
	this.instance_18.parent = this;
	this.instance_18.setTransform(353.2,425.4,1.2,1.3,0,0,0,0.1,0.1);

	this.instance_19 = new lib.triangle_red_clip();
	this.instance_19.parent = this;
	this.instance_19.setTransform(342.9,182.3,1.5,1.5,0,0,0,0.1,-0.1);

	this.instance_20 = new lib.rect_red_clip();
	this.instance_20.parent = this;
	this.instance_20.setTransform(1190.4,426.1,1,1,0,0,0,0,8.9);

	this.instance_21 = new lib.triangle_red_clip();
	this.instance_21.parent = this;
	this.instance_21.setTransform(466.2,500.4,1.5,1.5,90,0,0,0.1,-0.1);

	this.timeline.addTween(TTF(cjs.Tween.get({})).toE({state:[{t:this.instance_21},{t:this.instance_20},{t:this.instance_19},{t:this.instance_18},{t:this.instance_17},{t:this.instance_16},{t:this.instance_15},{t:this.instance_14},{t:this.instance_13},{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence1 = function(mode,startPosition,loop=false) {
	this.initialize(mode,startPosition,loop,{});

	// stage
	this.instance = new lib.line1();
	this.instance.parent = this;
	this.instance.setTransform(507.4,302.7,0.9,0.9,0,180,0,0.1,-0.1);

	this.instance_1 = new lib.rect_orange_clip();
	this.instance_1.parent = this;
	this.instance_1.setTransform(1145.9,138.6,1,1,45,0,0,0.2,0);

	this.instance_2 = new lib.line1();
	this.instance_2.parent = this;
	this.instance_2.setTransform(1025.8,329.8,0.9,0.9,0,0,0,0.1,-0.1);

	this.instance_3 = new lib.triangle_orange_clip();
	this.instance_3.parent = this;
	this.instance_3.setTransform(1066.8,388.6,1.8,1.8,-180,0,0,0.1,0.5);

	this.instance_4 = new lib.triangle_orange_clip();
	this.instance_4.parent = this;
	this.instance_4.setTransform(1076.8,124.2,0.95,0.95,-61,0,0,0.1,0.4);

	this.instance_5 = new lib.triangle_orange_clip();
	this.instance_5.parent = this;
	this.instance_5.setTransform(516.2,217.5,1.6,1.6,90,0,0,0.2,0.1);

	this.instance_6 = new lib.rc_conbine_clip();
	this.instance_6.parent = this;
	this.instance_6.setTransform(1039.6,281.9,0.88,0.88);

	this.instance_7 = new lib.rc_conbine_clip();
	this.instance_7.parent = this;
	this.instance_7.setTransform(457.4,185.4,0.84,0.84);

	this.instance_8 = new lib.rc_conbine_clip();
	this.instance_8.parent = this;
	this.instance_8.setTransform(405.5,364.1);

	this.instance_9 = new lib.line1();
	this.instance_9.parent = this;
	this.instance_9.setTransform(1148.9,484,0.6,0.6,0,180,0,0.1,-0.1);

	this.instance_10 = new lib.line1();
	this.instance_10.parent = this;
	this.instance_10.setTransform(640.3,155.6,0.7,0.7,0,90,-90,0.3,-0.2);

	this.instance_11 = new lib.line1();
	this.instance_11.parent = this;
	this.instance_11.setTransform(458.2,512.1,0.7,0.7,0,180,0);

	this.instance_12 = new lib.dot1_clip();
	this.instance_12.parent = this;
	this.instance_12.setTransform(1106.7,315.1,0.5,0.5,0,0,0,38.2,-3.4);

	this.instance_13 = new lib.dot1_clip();
	this.instance_13.parent = this;
	this.instance_13.setTransform(438,290.8,0.5,0.5,0,0,0,38,-3.3);

	this.instance_14 = new lib.triangle_clip();
	this.instance_14.parent = this;
	this.instance_14.setTransform(1085.8,286.2,0.4,0.4,-90,0,0,4.5,0.3);

	this.instance_15 = new lib.triangle_clip();
	this.instance_15.parent = this;
	this.instance_15.setTransform(1132.5,476,1.06,1.06,-90,0,0,4.6,0.3);

	this.instance_16 = new lib.triangle_clip();
	this.instance_16.parent = this;
	this.instance_16.setTransform(458.9,369,0.5,0.5,-90,0,0,4.6,0.2);

	this.instance_17 = new lib.circle_orange_clip();
	this.instance_17.parent = this;
	this.instance_17.setTransform(468.8,434.5,0.8,0.8,180,0,0,6,0.1);

	this.instance_18 = new lib.circle_orange_clip();
	this.instance_18.parent = this;
	this.instance_18.setTransform(574.9,139,0.4,0.4,-90,0,0,6,0.1);

	this.instance_19 = new lib.circle2_clip();
	this.instance_19.parent = this;
	this.instance_19.setTransform(1059.8,183.5,0.88,0.88,90,0,0,0.1,-0.1);

	this.instance_20 = new lib.circle_orange_clip();
	this.instance_20.parent = this;
	this.instance_20.setTransform(1054.2,232.7,0.64,0.64,0,0,0,6,0.1);

	this.instance_21 = new lib.circle2_clip();
	this.instance_21.parent = this;
	this.instance_21.setTransform(452.7,214.1,0.61,0.61,90,0,0,0.1,-0.1);

	this.instance_22 = new lib.four_in_one_set();
	this.instance_22.parent = this;
	this.instance_22.setTransform(790.8,333);

	this.instance_23 = new lib.dot1_clip();
	this.instance_23.parent = this;
	this.instance_23.setTransform(409.6,428.5,0.8,0.8,90,0,0,38.1,-3.5);

	this.timeline.addTween(TTF(cjs.Tween.get({})).toE({state:[{t:this.instance_23},{t:this.instance_22},{t:this.instance_21},{t:this.instance_20},{t:this.instance_19},{t:this.instance_18},{t:this.instance_17},{t:this.instance_16},{t:this.instance_15},{t:this.instance_14},{t:this.instance_13},{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence6 = function(mode,startPosition,loop=false) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層 1
	this.instance = new lib.giftbox();
	this.instance.parent = this;
	this.instance.setTransform(772.5,349.3);

	this.instance_1 = new lib.line1();
	this.instance_1.parent = this;
	this.instance_1.setTransform(958.8,168.9,0.8,0.8,0,0,0,0.3,-0.1);

	this.instance_2 = new lib.line1();
	this.instance_2.parent = this;
	this.instance_2.setTransform(1036,333.7,1,1,0,0,0,0.4,-0.1);

	this.instance_3 = new lib.triangle_clip();
	this.instance_3.parent = this;
	this.instance_3.setTransform(1036.1,338.1,1.03,1.03,90,0,0,4.5,-0.1);

	this.instance_4 = new lib.dot1_clip();
	this.instance_4.parent = this;
	this.instance_4.setTransform(1011.1,509.7,0.53,0.53,0,0,0,38.1,-3.4);

	this.instance_5 = new lib.dot1_clip();
	this.instance_5.parent = this;
	this.instance_5.setTransform(518.2,411.1,0.73,0.73,90,0,0,38,-3.4);

	this.instance_6 = new lib.line1();
	this.instance_6.parent = this;
	this.instance_6.setTransform(412.5,481,0.6,0.6,0,180,0,0.1,0);

	this.instance_7 = new lib.circle2_clip();
	this.instance_7.parent = this;
	this.instance_7.setTransform(461.4,200.3,0.88,0.88);

	this.instance_8 = new lib.circle_green_clip();
	this.instance_8.parent = this;
	this.instance_8.setTransform(989.6,493.8,1.22,1.22);

	this.instance_9 = new lib.circle_green_clip();
	this.instance_9.parent = this;
	this.instance_9.setTransform(471.7,459.5,1.22,1.22);

	this.instance_10 = new lib.circle_green_clip();
	this.instance_10.parent = this;
	this.instance_10.setTransform(406.3,263,0.7,0.7);

	this.instance_11 = new lib.circle_green_clip();
	this.instance_11.parent = this;
	this.instance_11.setTransform(457.5,150.7,0.7,0.7);

	this.instance_12 = new lib.circle_green_clip();
	this.instance_12.parent = this;
	this.instance_12.setTransform(978.9,130.9);

	this.instance_13 = new lib.rect_green_clip();
	this.instance_13.parent = this;
	this.instance_13.setTransform(582.3,253.1,0.7,0.7,0,0,0,46.4,40.4);

	this.instance_14 = new lib.rect_green_clip();
	this.instance_14.parent = this;
	this.instance_14.setTransform(406.2,367.5,0.7,0.7,0,0,0,46.4,40.4);

	this.instance_15 = new lib.circle2_clip();
	this.instance_15.parent = this;
	this.instance_15.setTransform(1096,211.1,0.6,0.6,0,0,0,0.1,0);

	this.instance_16 = new lib.dot1_clip();
	this.instance_16.parent = this;
	this.instance_16.setTransform(1083.4,250.5,0.48,0.48,90,0,0,38.1,-3.5);

	this.instance_17 = new lib.line1();
	this.instance_17.parent = this;
	this.instance_17.setTransform(915.9,147.1,0.8,0.8,-90,0,0,0.3,-0.1);

	this.instance_18 = new lib.rc_conbine_clip();
	this.instance_18.parent = this;
	this.instance_18.setTransform(1027.2,63.5,0.33,0.33,0,0,0,0,0.1);

	this.instance_19 = new lib.rc_conbine_clip();
	this.instance_19.parent = this;
	this.instance_19.setTransform(1096.3,178.3,0.85,0.85,0,0,0,0.1,0.1);

	this.instance_20 = new lib.rc_conbine_clip();
	this.instance_20.parent = this;
	this.instance_20.setTransform(442.2,106.8,0.85,0.85,0,0,0,0.1,0.1);

	this.instance_21 = new lib.rc_conbine_clip();
	this.instance_21.parent = this;
	this.instance_21.setTransform(506.3,354.3,0.85,0.85,0,0,0,0.1,0.1);

	this.instance_22 = new lib.triangle_clip();
	this.instance_22.parent = this;
	this.instance_22.setTransform(488.2,99.8,0.51,0.51,90,0,0,4.5,-0.1);

	this.instance_23 = new lib.triangle_clip();
	this.instance_23.parent = this;
	this.instance_23.setTransform(513.1,305.8,0.34,0.34,180,0,0,4.4,-0.3);

	this.instance_24 = new lib.triangle_clip();
	this.instance_24.parent = this;
	this.instance_24.setTransform(481.9,524.3,0.51,0.51,90,0,0,4.5,-0.1);

	this.instance_25 = new lib.rc_conbine_clip();
	this.instance_25.parent = this;
	this.instance_25.setTransform(534.5,534.4);

	this.instance_26 = new lib.triangle_green_clip();
	this.instance_26.parent = this;
	this.instance_26.setTransform(1121.5,427.4,2.19,2.19,0,0,0,5,-0.1);

	this.instance_27 = new lib.triangle_green_clip();
	this.instance_27.parent = this;
	this.instance_27.setTransform(1037.5,216.1,0.88,0.88,90,0,0,5,-0.1);

	this.instance_28 = new lib.triangle_green_clip();
	this.instance_28.parent = this;
	this.instance_28.setTransform(478.3,262.6,0.49,0.49,180,0,0,4.9,-0.1);

	this.instance_29 = new lib.triangle_green_clip();
	this.instance_29.parent = this;
	this.instance_29.setTransform(348.4,315.9,0.49,0.49,90,0,0,4.9,0);

	this.timeline.addTween(TTF(cjs.Tween.get({})).toE({state:[{t:this.instance_29},{t:this.instance_28},{t:this.instance_27},{t:this.instance_26},{t:this.instance_25},{t:this.instance_24},{t:this.instance_23},{t:this.instance_22},{t:this.instance_21},{t:this.instance_20},{t:this.instance_19},{t:this.instance_18},{t:this.instance_17},{t:this.instance_16},{t:this.instance_15},{t:this.instance_14},{t:this.instance_13},{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence1_text = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層 1
	this.instance = new lib.sence_1_text();
	this.instance.parent = this;
	this.instance.setTransform(770,540);

	this.timeline.addTween(TTF(cjs.Tween.get(this.instance)).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence2_text = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層_1
	this.instance = new lib.sence_2_text();
	this.instance.parent = this;
	this.instance.setTransform(773,540);

	this.timeline.addTween(TTF(cjs.Tween.get(this.instance)).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence3_text = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層_1
	this.instance = new lib.sence_3_text();
	this.instance.parent = this;
	this.instance.setTransform(770.7,540);

	this.timeline.addTween(TTF(cjs.Tween.get(this.instance)).waitE(601));

}).prototype = p = new cjs.MovieClip();

(lib.sence4_text = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層_1
	this.instance = new lib.sence_4_text();
	this.instance.parent = this;
	this.instance.setTransform(770.3,540);

	this.timeline.addTween(TTF(cjs.Tween.get(this.instance)).waitE(1));

}).prototype = p = new cjs.MovieClip();

(lib.sence5_text = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層_1
	this.instance = new lib.sence_5_text();
	this.instance.parent = this;
	this.instance.setTransform(772.7,540);

	this.timeline.addTween(TTF(cjs.Tween.get(this.instance)).waitE(720));

}).prototype = p = new cjs.MovieClip();

(lib.sence6_text = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// 圖層_1
	this.instance = new lib.sence_6_text();
	this.instance.parent = this;
	this.instance.setTransform(774.2,540);

	this.timeline.addTween(TTF(cjs.Tween.get(this.instance)).waitE(720));

}).prototype = p = new cjs.MovieClip();

p.nominalBounds = new cjs.Rectangle(1131.3,455.5,785.3,514.2);
// library properties:
console.log(window.innerHeight);
lib.properties = {
	id: '95E7FF230D313B4DB547EDECE175D1C9',
	width: 1536,
	height:723,
	fps: 60,
	color: "#333333",
	opacity: 1.00,
	manifest: [
		{src:"/img/animate/sence1_atlas_.png", id:"sence1_atlas_"},
		{src:"/img/animate/sence2_atlas_.png", id:"sence2_atlas_"},
		{src:"/img/animate/sence3_atlas_.png", id:"sence3_atlas_"},
		{src:"/img/animate/sence4_atlas_.png", id:"sence4_atlas_"},
		{src:"/img/animate/sence5_atlas_.png", id:"sence5_atlas_"},
		{src:"/img/animate/sence6_atlas_.png", id:"sence6_atlas_"},
		{src:"/img/animate/sence1_text_atlas_"+(isEN?"en_":"")+".png", id:"sence1_text_atlas_"},
		{src:"/img/animate/sence2_text_atlas_"+(isEN?"en_":"")+".png", id:"sence2_text_atlas_"},
		{src:"/img/animate/sence3_text_atlas_"+(isEN?"en_":"")+".png", id:"sence3_text_atlas_"},
		{src:"/img/animate/sence4_text_atlas_"+(isEN?"en_":"")+".png", id:"sence4_text_atlas_"},
		{src:"/img/animate/sence5_text_atlas_"+(isEN?"en_":"")+".png", id:"sence5_text_atlas_"},
		{src:"/img/animate/sence6_text_atlas_"+(isEN?"en_":"")+".png", id:"sence6_text_atlas_"}
	],
	preloads: []
};

// bootstrap callback support:

(lib.Stage = function(canvas) {
	cjs.Stage.call(this, canvas);
}).prototype = p = new cjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['95E7FF230D313B4DB547EDECE175D1C9'] = {
	getStage: function() { return exportRoot.getStage(); },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}

}

