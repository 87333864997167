<template>
	<section class="animationBanner">
		<!-- <div class="loading" v-if="!isLoad">
			<h2>Loading...</h2>
		</div> -->
		<canvas ref="canvas"></canvas>
	</section>
</template>

<script>
import libraryGenerator from '@/animate';
import createjs from 'createjs-npm';
// import createjs from '@/createjs';

 let AdobeAn = {};
export default {
  name: 'animate-banner',
  data(){
  	return {
  		sdk:{
  			lib:null,
  			stage:null,
  			pageArray:null,
  		},
  		ctrl:{
  			currentPage:0,
  			total:6,
	  		Animated:true,
  		},
  		isLoad:false,
  	}
  },
  mounted(){
	this.initLibrary();
  },
  methods:{
  	initLibrary(){
	  		libraryGenerator(createjs,AdobeAn,'En');
			var comp=AdobeAn.getComposition("95E7FF230D313B4DB547EDECE175D1C9");
	  		this.sdk.lib=comp.getLibrary();
			var loader = new createjs.LoadQueue(false);
	  		loader.addEventListener("fileload", (evt)=>{this.handleFileLoad(evt,comp)});
			loader.addEventListener("complete", (evt)=>{this.handleComplete(evt,comp)});
			loader.loadManifest(this.sdk.lib.properties.manifest);
  	},
	handleFileLoad(evt,comp){
		var images=comp.getImages();	
		if (evt && (evt.item.type == "image")) { images[evt.item.id] = evt.result;}
	},
	handleComplete(evt,comp){
		this.isLoad = true;
		let lib = this.sdk.lib;
		let ss=comp.getSpriteSheet();
		let queue = evt.target;
		let ssMetadata = lib.ssMetadata;
		for(let i=0; i<ssMetadata.length; i++) {
		ss[ssMetadata[i].name] = new createjs.SpriteSheet( {"images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames} )
		}
		this.sdk.stage = new lib.Stage(this.$refs.canvas);
		AdobeAn.compositionLoaded(lib.properties.id);
		
		this.resizeCanvas();
		this.fnStartAnimation();
		this.goPage(0);
	},
	resizeCanvas(){
		var lastW, lastH, lastS=1;		
		var w = this.sdk.lib.properties.width, h = this.sdk.lib.properties.height;		
		var iw = window.innerWidth, ih=window.innerHeight;
		var pRatio = 1.25, xRatio=iw/w, yRatio=ih/h, sRatio=1;	
		if(this.$refs.canvas){
			this.$refs.canvas.width = w*pRatio*sRatio;		
			this.$refs.canvas.height = h*pRatio*sRatio;
		}		
		this.sdk.stage.scaleX = pRatio*sRatio;			
		this.sdk.stage.scaleY = pRatio*sRatio;
		lastW = iw; lastH = ih; lastS = sRatio;            
		this.sdk.stage.tickOnUpdate = false;            
		this.sdk.stage.update();
		this.sdk.stage.tickOnUpdate = true;
	},
	fnStartAnimation(){
		createjs.Ticker.setFPS(30);
		createjs.Ticker.addEventListener("tick", this.sdk.stage);
	},
	goPage(pageIndex){
  		this.ctrl.Animated = false;
  		setTimeout(()=>{
			this.ctrl.Animated = true;
		},1000);
		this.ctrl.currentPage = pageIndex;
		this.resizeCanvas();
		this.sdk.stage.removeChildAt(0);
		this.sdk.stage.removeChildAt(0);
		let sence = new this.sdk.lib['sence'+(1+pageIndex)]();
		let text = new this.sdk.lib['sence'+(1+pageIndex)+'_text']();
		this.sdk.stage.addChild(sence);
		this.sdk.stage.addChild(text);
		sence.timeline.addEventListener("complete", ()=>{
			this.goNext();
		})
	},
	goNext(){
		if(this.ctrl.currentPage<this.ctrl.total-1){
			this.goPage(this.ctrl.currentPage+1)
		}else{
			this.goPage(0);
		}
	},
  }
}
</script>
<style type="text/css">
	.HomePage .animationBanner{
		/*background-color:rgba(51, 51, 51, 1.00);*/
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content:center; 
	}
	.HomePage .animationBanner canvas{
		max-width: 100vw;
		max-height: 100vh;
	}
	.HomePage .animationBanner .front{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;}
	.HomePage .animationBanner .front .container{
	    max-width: 1400px;
	    margin: auto;
	    position: relative;
	    width: 100%;
	    height: 100%;
	}
	.HomePage .animationBanner .front .pagi{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;}
	.HomePage .animationBanner .front .pagi nav{
		border: 1px solid #ffffff;
		width: 12px;
		height: 12px;
		margin: 13px 0;
		transition: 0.3s;
		cursor: pointer;
	}
	.HomePage .animationBanner .front .pagi.animated nav{
		transform: rotate(45deg);
	}
	.HomePage .animationBanner .front .pagi nav.active{
		background-color: #fff;
		width: 15px;
		height: 15px;
	}
	.HomePage .animationBanner .loading{
	    position: absolute;
	}
	.HomePage .animationBanner .loading h2{
		color: #fff;
		letter-spacing: 3px;
		font-size: 13px;
		animation: 0.5s loadingFlash infinite;
	}
	@keyframes loadingFlash {
		50%{
			color: transparent;
		}
	}
</style>